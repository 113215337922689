define(['$window', 'app', 'accessibilityFocusHelper'], ($window, app, accessibilityFocusHelper) => {

  const shopBySwatch = () => {
    const component = {};

    let _config = {
      dataAttributes: {
        dataPrimary: 'data-primary-colour',
        dataColourGroup: 'data-colourgroup',
        dataFacetValue: 'data-facet-value',
        dataShade: 'data-shade'
      },
      selectors: {
        primaryColourContainer: '.shopBySwatch_primary_colour_button',
        shadeColourContainer: '.shopBySwatch_shade_group',
        checkboxes: '.responsiveFacets_sectionItemCheckbox',
        swatchShades: '.shopBySwatch_shades',
        step2: '.shopBySwatch_step_2',
        viewMore: '.view_more',
        shadesContainer: '.shopBySwatch_shades_container',
        step1: '.shopBySwatch_step_1',
        colours: '.shopBySwatch_colors',
        checkboxesClass: 'responsiveFacets_sectionItemCheckbox'
      },
      classes: {
        selected: 'selected',
        display: 'display'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.step1 = component.element.querySelector(component.config.selectors.step1);

      app.subscribe('shopBySwatch/facetUpdate', component.facetUpdate)
      component.facetUpdate([]);

      return component;
    };

    const _primarySuccessHandler = (res) => {
      component.colours = component.element.querySelector(component.config.selectors.colours);
      if(component.colours) {
        component.element.removeChild(component.colours);
      }
      component.step1.insertAdjacentHTML('afterend', res);
      component.addEventListeners();
    }

    const _facetUpdate = (res) => {
      let response = res.join('|');
      component.selectedFacets = response;
      let path = window.location.pathname;
      let updatedPath = path.replace('.list', '.swatches');
      let url = window.location.protocol + '//' + window.location.hostname + "/section" + updatedPath +"?facetFilters=" + response;

      app.ajax.get({
        url: url,
        success: component.primarySuccessHandler,
        error: (err) => console.log(`cant get shades - ${err}`)
      });
    }

    const _addEventListeners = () => {
      component.primaryColourContainer = component.element.querySelectorAll(component.config.selectors.primaryColourContainer);
      component.primaryColourContainer.forEach(colour => {
        colour.addEventListener('click', () => {
          component.primaryColour = colour.getAttribute(component.config.dataAttributes.dataPrimary);
          component.primaryColourClicked(component.primaryColour);
        })
      });

    }

    const _primaryColourClicked = (primaryColour) => {
      let path = window.location.pathname;
      let updatedPath = path.replace('.list', '.swatches');
      let url = window.location.protocol + '//' + window.location.hostname + "/section" + updatedPath +
        "?facetFilters=" + "en_hbg_colourGroup_content:" + primaryColour;
      if(!component.selectedFacets === undefined && component.selectedFacets.length !== 0) {
        url = url + "|" + component.selectedFacets
      }


      app.ajax.get({
        url: url,
        success: component.shadesSuccessHandler,
        error: (err) => console.log(`cant get shades - ${err}`)
      });
    }

    const _shadesSuccessHandler = (res) => {
      component.colours = component.element.querySelector(component.config.selectors.colours);
      component.element.removeChild(component.colours);
      component.step1.insertAdjacentHTML('afterend', res);
      component.swatchShades = component.element.querySelector(component.config.selectors.swatchShades);
      component.swatchShades.classList.add(component.config.classes.display);
      component.step2 = component.element.querySelector(component.config.selectors.step2);
      component.addEventListeners();
      component.setYscroll();
      component.shadesContainer = component.element.querySelector(component.config.selectors.shadesContainer);
      accessibilityFocusHelper.focus(component.shadesContainer);
      component.shadeColourContainer = component.element.querySelectorAll(component.config.selectors.shadeColourContainer);
      component.shadeColourContainer.forEach(shade => {
        shade.addEventListener('click', () => {
          component.shadeClicked(shade);
        })
      });
      if(component.shadeColourContainer.length > 10) {
        component.updateViewMore();
      } else {
        component.shadesContainer = component.element.querySelector(component.config.selectors.shadesContainer);
        component.shadesContainer.style.height = 'auto';
      }
    }

    const _shadeClicked = (shade) => {
      component.selectedShade = shade.getAttribute(component.config.dataAttributes.dataShade);
      component.updatedShadeName = component.selectedShade.replace(/\s/g, '+');
      component.facetList = document.querySelectorAll('[data-facet-value="' + component.updatedShadeName + '"]');
      component.facetList.forEach(item => {
        if(item.classList.contains(component.config.selectors.checkboxesClass)) {
          component.facet = item;
        }
      })
      if(!shade.classList.contains(component.config.classes.selected)) {
        shade.classList.add(component.config.classes.selected);
        if(!component.facet.checked) {
          component.facet.checked = true;
          component.publishFacetClick(component.facet)
        }
      }
    }

    const _updateViewMore = () => {
      component.viewMore = component.element.querySelector(component.config.selectors.viewMore);
      component.viewMore.classList.add(component.config.classes.display);
      component.shadesContainer = component.element.querySelector(component.config.selectors.shadesContainer);
      component.viewMore.addEventListener('click', () => {
        component.shadesContainer.style.height = 'auto';
        component.viewMore.classList.remove(component.config.classes.display);
      })
    }

    const _deSelectSwatches = () => {
      const colourNameList = document.querySelectorAll('input[name="en_colourName_content"]');
      colourNameList.forEach(colour => {
        if(colour.checked) {
          colour.checked = false;
          component.checkboxes = document.querySelectorAll(component.config.selectors.checkboxes);
          component.publishFacetClick(colour);
          component.setYscroll();
        }
      });

    }

    const _publishFacetClick = (indexOf) => {
      component.checkboxes = document.querySelectorAll(component.config.selectors.checkboxes);
      let index = Array.prototype.indexOf.call(component.checkboxes, indexOf);
      app.publish('shopBySwatchFacetClick', index, true);
    }

    const _setYscroll = () => {
      let yOffset = -100;
      let y = component.step2.getBoundingClientRect().top + $window.pageYOffset + yOffset;
      $window.scrollTo({top: y, behavior: 'smooth'});
    }

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.primaryColourClicked = _primaryColourClicked;
    component.updateViewMore = _updateViewMore;
    component.shadeClicked = _shadeClicked;
    component.shadesSuccessHandler = _shadesSuccessHandler;
    component.primarySuccessHandler = _primarySuccessHandler;
    component.facetUpdate = _facetUpdate;
    component.deSelectSwatches = _deSelectSwatches;
    component.publishFacetClick = _publishFacetClick;
    component.setYscroll = _setYscroll;

    return component;
  };

  return shopBySwatch;

});
